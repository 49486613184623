<template>
  <div>
    <div class="authortitle">投稿</div>

    <div style="padding: 20px">
      <div class="form-title">文章信息:</div>
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
        style="width: 60%"
      >
        <el-form-item label="稿件">
          <el-upload
            class="upload-demo"
            ref="upload"
            :action="path"
            :file-list="fileList"
            :data="form"
            :on-change="changfile"
            :on-success="uploadsuccsee"
			:before-upload="beforeUpload"
            :auto-upload="false"
			accept=".doc,.docx"
          >
            <div class="downloadfj" slot="trigger">
				
              <i class="el-icon-paperclip"></i>点击上传附件
            </div>
			 <div slot="tip" class="el-upload__tip">只能上传单个word文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="论题" prop="title">
          <el-input v-model="form.title" placeholder="请输入论题"></el-input>
        </el-form-item>
        <el-form-item label="摘要">
          <el-input
            v-model="form.summary"
            placeholder="请输入摘要"
            type="textarea"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="关键词">
          <el-input
            v-model="form.keyword"
            placeholder="请输入关键词"
            type="textarea"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.remarks"
            placeholder="请输入备注"
            type="textarea"
            resize="none"
          ></el-input>
        </el-form-item>
        <div class="form-title">选择期刊:</div>
        <el-form-item label="选择期刊">
          <el-button @click="selectqk">点击选择期刊</el-button>
          <div v-if="select">已选投递期刊：{{ qkname }}</div>
        </el-form-item>
        <el-form-item label="投稿属性" prop="attribute">
          <el-select
            v-model="form.attribute"
            placeholder="请选择投稿属性"
            clearable
          >
            <el-option label="普通" value="0"></el-option>
            <el-option label="加急" value="1"></el-option>
          </el-select>
        </el-form-item>
        <div class="form-title">作者信息:</div>
        <el-form-item label="姓名" prop="editor">
          <el-input v-model="form.editor"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="单位">
          <el-input v-model="form.unitt" placeholder="请输入单位"></el-input>
        </el-form-item>
        <el-form-item label="地区">
          <v-distpicker hide-area @selected="showregin"></v-distpicker>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input
            v-model="form.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮编">
          <el-input v-model="form.postcode" placeholder="请输入邮编"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input
            v-model="form.briefintroduction"
            placeholder="请输入作者简介"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="checked">同意投稿协议</el-checkbox>
        </el-form-item>
        <el-form-item label="">
          <el-button type="danger" @click="submitUpload('form')" :disabled="jybtn"
            >重新上传</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import VDistpicker from 'v-distpicker'

export default {
  components: {
    VDistpicker
  },
  data () {
	  //验证手机号的规则;
	  var checkMobile = (rule, value, cb) => {
	    // 验证手机号的正则表达式
	    const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
	    if (regMobile.test(value)) {
	      return cb();
	    }
	    cb(new Error("请输入合法的手机号"));
	  };
    return {
      form: {
        title: '',
        summary: '',
        keyword: '',
        remarks: '',
        attribute: '',
        qk_id: "",
        editor: '',
        phone: '',
        unitt: '',
        region: '',
        address: '',
        postcode: '',
        briefintroduction: '',
        author_id: "",
        
      },
	  qkname: "",
	    checked: false,
	   fileList: [],
	   "province": "",
	   "city": "",
	   "path":"",
	   jybtn:true,
	   file:"",
	   select:false,
	    rules: {
	             editor: [
	               { required: true, message: '请输入作者姓名', trigger: 'blur' },
	              
	             ],
	             phone: [
	               { required: true, message: '请输入电话号码', trigger: 'blur' },
				   {
				     validator: checkMobile,
				     message: "请输入正确的手机号码",
				     trigger: "blur"
				   }
	             ],
				 attribute:[
	               { required: true, message: '请选择订单属性', trigger: 'blur' }
	             ],
				 title:[
					 { required: true, message: '请输入论题', trigger: 'blur' } 
				 ]
				},
				
    }

  },
  mounted () {
    this.path = this.URL + "/author/addworks"
    this.form.author_id = localStorage.getItem('ZZId')

  },
  activated () {
    if (sessionStorage.getItem("tgqkname")) {
      this.qkname = sessionStorage.getItem("tgqkname")
      this.select = true
      this.form.qk_id = sessionStorage.getItem("tgqk")

    }
  },
  methods: {
    selectqk () {//跳转选择期刊
      this.$router.push('/Pending/contribute/selectmagzine')
    },
    submitUpload (formName) {//文件上传
	this.$refs[formName].validate((valid) => {
	          if (valid) {
	           if (this.fileList == "") {
	             this.$message.error("未上传稿件")
	           } else if (!sessionStorage.getItem("tgqkname")) {
	             this.$message.error("未选择投递期刊")
	           } else {
	             this.$refs.upload.submit();
	           }
	          } else {
	            console.log('error submit!!');
	            return false;
	          }
	        });
    
    }, uploadsuccsee (res) {//文件上传成功后，清除session
      if (res.code == 0) {
        this.$message.success("上传成功")
        setTimeout(function () {
          location.reload()
          sessionStorage.clear()
        }, 2000)
      } else {
        this.$message.error("投稿失败")
        setTimeout(function () {
          location.reload()
        }, 2000)
      }


    },beforeUpload(file) {
            console.log(file)
            var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
            const extension = testmsg === 'doc'
            const extension2 = testmsg === 'docx'
            // const isLt2M = file.size / 1024 / 1024 < 10
            if(!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 doc、docx格式!',
                    type: 'warning'
                });
            }
            return extension || extension2
        },
    changfile (file, fileList) {//文件上传选择文件后触发的
       let that = this
       that.fileList = fileList
      that.file = file
      console.log(file, fileList)
     
      // 读取文件的摘要
      
      var param = new FormData(); //创建form对象
     
      param.append('file', that.file.raw) 
  
      that.$axios({
        method: 'post',
        url: '/PoiWord/upload',
        data: param,
        headers: {
          'Content-Type': 'multipart/form-data',

        },
      }).then(function (res) {
        that.form.summary = res.data.zy
   

      })
    }, showregin (e) {
      this.form.region = e.province.value + e.city.value
      console.log(this.form.region)
    }
  }, watch: {//监测是否同意了协议
    checked (val) {
      this.jybtn = !val
    }
  }
}

</script>

<style>
.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.form-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.downloadfj {
  width: 220px;
  height: 40px;
  padding-left: 20px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
}
</style>